<template>
  <div class="cashbackPage">
    <div class="page-header">
      <div class="page-header__wrapper">
        <div class="page-header__left">
          <h3 class="title">Cashback</h3>
        </div>
        <div style="display: flex">
          <div
            class="buttons-container u-display-flex u-justify-content--between u-align-items--center"
          >
            <el-button
              type="primary"
              icon="el-icon-top"
              size="large"
              @click="sheetActionClicked('settle')"
            >
              Settle Cashback
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-bottom"
              size="large"
              @click="sheetActionClicked('export')"
            >
              Export Cashback
            </el-button>
          </div>
          <CashbackSheets
            :showDialog="uploaderDialog"
            :sheetAction="sheetAction"
            @closeDialog="onClickCloseDialog"
            @actionSucceed="actionSucceed"
          />
        </div>
      </div>
    </div>
    <!-- CashbackTable -->
    <styled-table :data="list" class="cashbackTable" style="width: 100%">
      <el-table-column
        v-for="col in redemptionTableCols"
        :key="col.prop"
        :prop="col.prop"
        :label="$t(col.label)"
      >
      </el-table-column>
      <el-table-column prop="cashbackBalance" label="Amount of Cashback">
        <template slot-scope="prop">
          <div>{{ prop.row.cashbackBalance }} EGP</div>
        </template>
      </el-table-column>
    </styled-table>
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :current-page="paginationCurrentPage"
        :total="pagination.totalResultsCount"
        @current-change="handlePagination"
        :page-size="25"
      ></el-pagination>
    </div>
    <!-- RatingsTable -->
  </div>
</template>

<script>
import styledTable from "../../../components/dataContainers/styledTable.vue";
import { cashbackService } from "@/services/cashback.service.js";
import { redemptionTableCols } from "../data";
import CashbackSheets from "./CashbackSheets.vue";
import { utils } from "@/utils";
export default {
  name: "CashbackPage",
  components: {
    CashbackSheets,
    styledTable,
  },
  data() {
    return {
      redemptionTableCols: redemptionTableCols,
      list: [],
      showCreateDialogFlag: false,
      originalData: [],
      updateErrorResponseObject: null,
      uploaderDialog: false,
      sheetAction: null,
      pagination: {},
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page;
    },
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
  },
  methods: {
    async getCashbackList(page = 1) {
      try {
        const response = await cashbackService.fetchCashbackList(page);
        let responseData = response.data.data.result;
        this.pagination = {
          totalPages: response.data.data.pages,
          totalResultsCount: response.data.data.count,
          nextPage: response.data.data.next,
        };
        this.originalData = JSON.parse(JSON.stringify(responseData));
        this.list = responseData;
        return response;
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    sheetActionClicked(chosenAction) {
      this.uploaderDialog = true;
      this.sheetAction = chosenAction;
    },
    onClickCloseDialog() {
      this.uploaderDialog = false;
      this.sheetAction = null;
    },
    async actionSucceed() {
      this.uploaderDialog = false;
      this.sheetAction = null;
      this.getCashbackList(this.currentPage);
    },
    handlePagination(val) {
      this.getCashbackList(val);
      this.$router.push({
        query: {
          page: val,
        },
      });
    },
  },
  async mounted() {
    this.getCashbackList(this.currentPage);
  },
};
</script>
<style lang="scss">
.cashbackPage {
  .page-header {
    .title {
      display: flex;
      justify-content: center;
      align-content: center;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fdcd12;
        font-size: 22px;
        margin-right: 4px;
      }
    }
  }
  .cashbackTable.el-table .cell {
    direction: ltr;
  }
  .cashbackTable .table-header:nth-child(1) {
    border-top-left-radius: 8px;
  }
  .cashbackTable .table-header:last-child {
    border-top-right-radius: 8px;
  }
  .cashbackTable .cell {
    direction: rtl;
  }
  .cashbackTable .table-header {
    background: #fccd13;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
