<template>
  <div>
    <!-- Uploader Dialog -->
    <el-dialog
      width="55%"
      :title="modalTitle"
      :visible.sync="localVisible"
      @close="closeDialog"
    >
      <div class="uploaderDialog">
        <div class="uploaderDialogBtn__wrapper">
          <div class="uploadContainer">
            <div
              class="summary"
              v-if="localSheetAction === 'settle'"
              style="margin-bottom: 12px"
            >
              <div class="summary__wrapper">
                <el-alert
                  :closable="false"
                  title="Please select start date and end date"
                  type="warning"
                  show-icon
                >
                </el-alert>
              </div>
            </div>
            <el-form
              :rules="rules"
              :model="dialogFormData"
              ref="cashbackFormRef"
            >
              <template>
                <div>
                  <div style="display: flex">
                    <el-form-item
                      :label="`Start Date`"
                      prop="startDate"
                      label-width="160px"
                      style="flex-basis: 50%"
                    >
                      <el-date-picker
                        v-model="dialogFormData.startDate"
                        type="datetime"
                        placeholder="Select date and time"
                        style="width: 100%"
                      >
                      </el-date-picker>
                    </el-form-item>

                    <el-form-item
                      label-width="160px"
                      :label="`End Date`"
                      prop="endDate"
                      style="flex-basis: 50%"
                    >
                      <el-date-picker
                        v-model="dialogFormData.endDate"
                        type="datetime"
                        placeholder="Select date and time"
                        style="width: 100%"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                </div>
              </template>
            </el-form>
            <el-upload
              v-if="localSheetAction === 'settle'"
              ref="excel-input"
              :on-change="uploadExcelFileChange"
              :on-remove="removeExcelFile"
              :file-list="sheetFileList"
              action="#"
              :multiple="false"
              :auto-upload="false"
            >
              <el-button slot="trigger" size="mini" type="primary"
                >Select File</el-button
              >
            </el-upload>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $t("GlobalsCancelBtn") }}</el-button>
        <el-button type="success" @click="uploadExcelSheet">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
      <a style="display: none" ref="single-sheet-download" target="_blank"></a>
    </el-dialog>

    <!-- Uploader Dialog -->
  </div>
</template>

<script>
import { cashbackService } from "@/services/cashback.service";
import { utils } from "@/utils";
export default {
  name: "CashbackSheets",
  props: {
    sheetAction: {
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: false,
      sheetFileList: [],
      localSheetAction: null,
      dialogFormData: null,
      modalTitle: "",
      rules: {
        startDate: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
        ],

        endDate: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    showDialog(value) {
      this.localVisible = value;
    },

    sheetAction(newVal) {
      this.localSheetAction = newVal;
      this.modalTitle =
        newVal === "settle" ? "Upload Settle Cashback" : "Export Cashback";
    },
  },
  created() {
    this.dialogFormData = { startDate: null, endDate: null };
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
      this.sheetFileList = [];
    },
    uploadExcelFileChange(file) {
      // Allow only one file in the file list not multiple
      this.sheetFileList = [file];
      const rawFile = this.sheetFileList[0];
      if (!rawFile) return;
      if (/\.(csv|xlsx)$/.test(rawFile.name)) {
        this.sheetHolder = rawFile;
      } else {
        this.$message({
          message: "File type should be .csv or .xlsx",
          type: "error",
          duration: 4000,
        });
        this.sheetFileList = [];
      }
    },
    removeExcelFile() {
      this.sheetFileList = [];
    },
    uploadExcelSheet() {
      this.$refs["cashbackFormRef"].validate((isValid) => {
        if (isValid) {
          if (this.localSheetAction === "settle") {
            this.sheetActionHandler();
          } else {
            this.handleSheetExport();
          }
        }
      });
    },
    restLocalState() {
      this.dialogFormData = { startDate: null, endDate: null };
      this.localVisible = false;
      this.localSheetAction = null;
    },
    sheetActionHandler() {
      let form = new FormData();
      form.append("file", this.sheetFileList[0].raw);
      const startDate = this.dialogFormData?.startDate;
      const endDate = this.dialogFormData?.endDate;

      cashbackService
        .uploadCashbackSheetAction(form, startDate, endDate)
        .then((response) => {
          if (response && response.data) {
            const responseData = response.data;
            let defaultType = "application/json; charset=utf-8";
            let responseContentType = response.headers["content-type"];
            if (defaultType === responseContentType) {
              let decodedString = String.fromCharCode.apply(
                null,
                new Uint8Array(responseData)
              );
              let parsedResponse = JSON.parse(decodedString);
              if (parsedResponse.data.message) {
                this.$globalFunctions.popupMessageWrapper(
                  parsedResponse.data.message,
                  "success",
                  2000
                );
                this.$emit("actionSucceed");
                this.restLocalState();
              } else {
                this.$globalFunctions.popupMessageWrapper(
                  "Success",
                  "success",
                  2000
                );
                this.$emit("actionSucceed");
                this.restLocalState();
              }
            } else {
              let blob = new Blob([responseData], {
                type: response.headers["content-type"],
              });
              this.$refs["single-sheet-download"].setAttribute(
                "href",
                window.URL.createObjectURL(blob)
              );
              this.$refs["single-sheet-download"].setAttribute(
                "download",
                `uploadErrorSheet.xlsx`
              );
              this.$refs["single-sheet-download"].click();
              this.$globalFunctions.popupMessageWrapper(
                "file uploaded successfully, please check the downloaded file",
                "success",
                4000
              );
              this.$emit("actionSucceed");
              this.restLocalState();
            }
          }
        })
        .catch((err) => {
          const errorMessage = utils.errorMessageExtractor(err);
          utils.popupMessageWrapper(errorMessage, "error", 3500);
        })
        .finally(() => {
          this.sheetFileList = [];
        });
    },
    async handleSheetExport() {
      try {
        const response = await cashbackService.fetchExportCashback(
          this.dialogFormData
        );

        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, "cashback", "xlsx");
          this.$emit("actionSucceed");

          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
          this.dialogFormData = { startDate: null, endDate: null };
        }
      } catch (error) {
        this.$message({
          message:
            utils.decodeArrayBuffer(error.response.data)?.message ??
            utils.errorMessageExtractor(error),
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  background-color: white;
  padding: 15px 10px;
  border: 0.5px solid #dcdfe6;
  border-radius: 5px;
}
</style>
